import { styled } from "@enerbit/base";
import LeftMenu from "../common/LeftMenu/LeftMenu";
import ListTabs from "../common/Tabs/ListTabs";
import { RootParams } from "../models/root-params/RootParams";

const LeftMenuServicePage = (params: RootParams) => {
    return (
        <LeftMenuPageContainer>
            <LeftMenu {...params} />
            <ContentContainer>
                <ListTabs
                    tabs={params.tabs}
                    callBackMenu={params.callBackMenu}
                />
            </ContentContainer>
        </LeftMenuPageContainer>
    );
};

export default LeftMenuServicePage;

const LeftMenuPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ContentContainer = styled("div")`
  padding: 1.5rem 2.25rem 2.25rem 2rem;
  width: 100%;
`;
