import MuiTabs from "./MuiTabs";

export interface TabModel {
    label: string;
    component: JSX.Element;
    disabled?: boolean;
}

export interface ListTabsProps {
    tabs: TabModel[];
    callBackMenu?: () => void;
}

const ListTabs = ({ tabs, callBackMenu }: ListTabsProps) => {
    return (
        <>
            <MuiTabs tabs={tabs} callBackMenu={callBackMenu} />
        </>
    );
};

export default ListTabs;
